import { Grid, GridItem, VStack } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";

import { lightTheme_colors, darkTheme_colors } from "../../../theme";


import PowerBallTable from "./Contents/PowerBallTable";
import CasinoSlotTable from "./Contents/CasinoSlotTable";
import HoldemTable from "./Contents/HoldemTable";
import DepositAndWithdrawTable from "./Contents/DepositAndWithdrawTable";
import UserMoneyTable from "./Contents/UserMoneyTable";
import BankTable from "./Contents/BankSelectTable";
import BankSettingTable from "./Contents/BankSettingTable";
import { SelectedMenuAtom } from "../../../atoms/topSelect";
import SiteGameDayTopSelect from "../CommContents/TopSelect/SiteGameDayTopSelect";
import SportTable from "./Contents/SportTable";
import GitaTable from "./Contents/GitaTable";





export default function SiteDetailSettlement() {
    // const selectedSite = useRecoilValue(SiteSelectAtom);
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);
    // const selectedBankSetting = useRecoilValue(BankSettingAtom);


    // 테마 관련 코드 
    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);
    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);

    return (
        <Grid padding={"0.2rem"}
            h='100%'
            templateColumns='repeat(1, 1fr)'
            templateRows='repeat(10, 1fr)'
            gap={1}
        >
            <GridItem borderRadius={"xl"} paddingX={"0.5rem"} colSpan={1} rowSpan={1}>
                <SiteGameDayTopSelect />
            </GridItem>
            <GridItem borderRadius={"xl"} paddingX={"0.5rem"} colSpan={1} rowSpan={9} >
                <VStack
                    height={"100%"}
                    width={"100%"}
                    overflowY="auto"
                    overflowX="hidden"
                    align={"start"}
                    css={{
                        '&::-webkit-scrollbar': {
                            width: '8px',
                            backgroundColor: scrollBarColor,
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: scrollBarThumbColor,
                            borderRadius: '4px',
                        },
                        '&:hover::-webkit-scrollbar-thumb': {
                            backgroundColor: scrollBarThumbHoverColor,
                        },
                    }}
                >
                    {SelectedMenu.category === "파워볼" && <PowerBallTable />}
                    {SelectedMenu.category === "카지노/슬롯" && <CasinoSlotTable />}
                    {SelectedMenu.category === "홀덤" && <HoldemTable />}
                    {SelectedMenu.category === "스포츠" && <SportTable />}
                    {SelectedMenu.category === "기타" && <GitaTable />}
                    {SelectedMenu.category === "충전/환전" && <DepositAndWithdrawTable />}
                    {SelectedMenu.category === "유저머니" && <UserMoneyTable />}
                    {(SelectedMenu.category === "뱅크" && SelectedMenu.BankSetting === false) && <BankTable />}
                    {(SelectedMenu.category === "뱅크" && SelectedMenu.BankSetting === true) && <BankSettingTable />}


                </VStack>
            </GridItem>
        </Grid>
    );
}
